.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

button:hover {
  opacity: 0.8;
}

body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Montserrat Variable', 'Montserrat Variable','Segoe UI', 'Roboto',
    'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  font-family: 'Montserrat Variable', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #fafafa;
}

/* Global Styles */
.container {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  min-width: 100vw;
  background-color: #3b9a83;
}

.containerWithBackground {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  min-width: 100vw;
  background-image: url('../images/merchantBackground.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.mainContainer {
  display: flex;
  height: calc(100vh - 90px);
  width: 30vw;
  height: 60vh;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: white;
  border-radius: 5px;
}

.mainContainer .title {
  margin-bottom: 4vh;
  margin-top: 2vh;
  padding: 0px 0px 0px;
  font-size: 20px !important;
}

.mainContainerAlt {
  display: flex;
  height: calc(100vh - 90px);
  width: 100vw;
  justify-content: center;
  align-items: center;
}

.mainContainerRegister {
  display: flex;
  height: calc(100vh - 90px);
  width: 30vw;
  height: 70vh;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: white;
  border-radius: 5px;
}

.mainContainerRegister .title {
  margin-bottom: 4vh;
  font-size: 20px !important;
}

.allWidth {
  width: 100vw;
}
.dafaultInputPhone {
  width: 30% !important;
}

.default_button {
  background-color: white !important;
  color: #3b9a83 !important;
  font-weight: 600;
  border: solid 1px #3b9a83 !important;
  cursor: pointer !important;
  width: 80%;
  height: 40px;
  cursor: pointer !important;
  border-radius: 5px !important;
  margin-top: 2vh !important;
}

.padding_top_10 {
  padding-top: 10px;
}

.padding_top_20 {
  padding-top: 20px;
}

.padding_top_40 {
  padding-top: 40px;
}

.padding_bottom_40 {
  padding-bottom: 40px;
}

.padding_left_right_30 {
  padding-left: 80px !important;
  padding-right: 80px !important;
}

/* Hide scrollbar for Chrome, Safari and Opera */
/* .scrollBarHidden::-webkit-scrollbar {
  display: none;
} */

/* Hide scrollbar for IE, Edge and Firefox */
.scrollBarHidden {
   /* -ms-overflow-style: none; */
  /* scrollbar-width: none; */
  
  width: 100%;
  height: 100%;
  border-radius: 20px;
  overflow-y: scroll;
  overflow-x: hidden;
  margin: 0;
  padding: 0;
  border-radius: 8px;
}

.scrollBarHidden::-webkit-scrollbar-thumb {
  background-color: #6eb89d;
  border: solid transparent;
  border-width: 4px 4px;
  border-radius: 7px;
  background-clip: padding-box;
}

.scrollBarHidden::-webkit-scrollbar {
  width: 14px;
  
}

.labelInputStyle {
  color: #434343 !important;
  opacity: 0.6 !important;
  font-family: Montserrat Variable !important;
  font-style: normal !important;
  font-weight: bold !important;
  font-size: 14px !important;
  margin-top: 10px !important;
}

.labelInputStyleForm {
  font-family: Montserrat Variable !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 14px !important;
  line-height: 14px !important;
  color: #434343 !important;
  opacity: 0.6 !important;
}

.labelInputStyleMerchantForm {
  font-family: Montserrat Variable !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 20px !important;
  line-height: 14px !important;
  color: #434343 !important;
  opacity: 0.6 !important;
}

.defaultInputText,
.defaultInputNumber {
  color: #434343 !important;
  padding: 12px 12px 0px 0px !important;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  margin-top: 5px;
  margin-bottom: 10px;
  border-radius: 4px;
  outline: none;
  background-color: fade(#fff, 80%);
  height: 100%;
  display: inline-block;
  box-sizing: border-box;
  border-top: none;
  border-left: none;
  border-right: none;
  min-width: 300px !important;
  font-weight: bold !important;
  font-family: Montserrat Variable !important;
  font-style: normal;
}

.defaultInputTextSmall {
  color: rgba(67, 67, 67, 0.6) !important;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  margin-top: 0px !important;
  border-radius: 4px;
  outline: none;
  background-color: fade(#fff, 80%);
  height: 100%;
  display: inline-block;
  box-sizing: border-box;
  border-top: none;
  border-left: none;
  border-right: none;
  min-width: 60px !important;
  font-weight: bold !important;
  font-family: Montserrat Variable !important;
  font-style: normal;
  font-size: 12px !important;
}

.defaultInputPhone {
  color: #434343 !important;
  padding: 12px 12px 0px 0px !important;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  margin-top: 5px;
  margin-bottom: 10px;
  border-radius: 4px;
  outline: none;
  background-color: fade(#fff, 80%);
  height: 100%;
  display: inline-block;
  box-sizing: border-box;
  border-top: none;
  border-left: none;
  border-right: none;
  font-weight: bold !important;
  font-family: Montserrat Variable !important;
  font-style: normal;
}

.defaultInputEmail {
  color: #434343 !important;
  padding: 12px 12px 0px 0px !important;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  margin-top: 5px;
  margin-bottom: 10px;
  border-radius: 4px;
  outline: none;
  background-color: fade(#fff, 80%);
  height: 100%;
  display: inline-block;
  box-sizing: border-box;
  border-top: none;
  border-left: none;
  border-right: none;
  font-weight: bold !important;
  font-family: Montserrat Variable !important;
  font-style: normal;
}

.defaultInputTextForm {
  font-family: Montserrat Variable !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 14px !important;
  line-height: 13px !important;
  color: #434343 !important;
  padding: 0px 0px 0px 0px !important;
}
.autocompleteMapInput-details {
  /* copiadas de default map input */
  margin-left: 12px;
  font-family: Montserrat Variable !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 14px !important;
  line-height: 13px !important;
  color: #434343 !important;
  width: 460px !important;
  padding: 0px 12px 12px 10px !important;

  border-top: none;
  border-left: none;
  border-right: none;
  border-color: #bdbdbd !important;
}
.autocompleteMapInput {
  /* copiadas de default map input */

  width: 100%;
  height: 30px;
  font-family: Montserrat Variable !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 16px !important;
  line-height: 13px !important;
  color: #434343 !important;
  padding: 10px !important;
  border-top: none;
  border-left: none;
  border-right: none;
  /* border-color: #d4d4d4 !important; */
  border: none;
  background-color: #EDEDED;
  border-radius: 10px;
  margin-bottom: 15px;
}

.autocompleteMapInput::--webkit-input-placeholder {
  margin-left: 20px !important;
  font-family: Montserrat Variable !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 14px !important;
  color: #d4d4d4 !important;
  height: 40px !important;
}
.pac-container {
  z-index: 99999 !important;
}

.defaultInputTextForm .MuiInput-underline:before {
  border-bottom: 2px solid #dbdbdb !important;
}

.inputForm .MuiInput-underline:before {
  border-bottom: 2px solid #dbdbdb !important;
}

.inputFormCountryCode .MuiInput-underline:before {
  border-bottom: 2px solid #dbdbdb !important;
}

.defaultInputSearchTextFormLarge .MuiInput-underline:before {
  border-bottom: 0px !important;
}

.defaultInputTextForm .MuiFormHelperText-root {
  font-family: Montserrat Variable !important;
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 12px;
  display: flex;
  align-items: center;
  color: #da7b7b;
}

.inputForm .MuiFormHelperText-root {
  font-family: Montserrat Variable !important;
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 12px;
  display: flex;
  align-items: center;
  color: #da7b7b;
}

.defaultInputTextFormLarge .MuiFormHelperText-root {
  font-family: Montserrat Variable !important;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 13px;
  display: flex;
  align-items: center;
  color: #da7b7b;
}

.defaultInputTextFormPlaceholder {
  font-family: Montserrat Variable !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 14px !important;
  color: #d4d4d4 !important;
  height: 40px !important;
}

.defaultInputTextFormLarge .MuiInputBase-root {
  font-family: Montserrat Variable !important;
  font-style: normal !important;
  border-radius: 5px;
  border-color: #bdbdbd !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  color: #828282 !important;
  width: 450px !important;
  height: 40px !important;
  margin-right: 20px !important;
  padding: 0px 0px 0px 10px !important;
}

.defaultInputSearchTextFormLarge .MuiInputBase-root {
  font-family: Montserrat Variable !important;
  font-style: normal !important;
  border: 1px solid !important;
  border-radius: 5px;
  border-color: #bdbdbd !important;
  font-weight: 600 !important;
  font-size: 14px !important;
  color: #434343 !important;
  width: 100%;
  max-width: 450px !important;
  height: 40px !important;
  margin-right: 20px !important;
  padding: 0px 0px 0px 10px !important;
}
.productButton {
  margin-left: 15px !important;
}

.MuiInputBase-input {
  color: #434343 !important;
  font-weight: 600 !important;
  /* border-bottom: 1px #828282 solid !important; */
  width: 70% !important;
}

.MuiInputBase-root-input {
  padding-right: 0px 0px 0px 10px !important;
}

.defaultInputTextForm .MuiInputBase-root {
  font-family: Montserrat Variable !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 14px !important;
  color: #434343 !important;
}

.defaultInputTextForm .MuiFormLabel-root {
  font-family: Montserrat Variable !important;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  color: #434343;
}

.defaultInputPassword {
  color: #828282 !important;
  padding: 12px 12px 0px 0px !important;
  text-shadow: 0 1px #fff;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  margin-top: 5px;
  margin-bottom: 5px;
  border-radius: 4px;
  outline: none;
  background-color: fade(#fff, 80%);
  display: inline-block;
  box-sizing: border-box;
  border-top: none;
  border-left: none;
  border-right: none;
  min-width: 200px !important;
  font-weight: bold !important;
  font-family: Montserrat Variable !important;
  font-style: normal;
}

.rowStyle {
  display: flex;
  flex-direction: row;
  text-align: center;
  align-items: center;
}

.wrapStyle {
  flex-wrap: wrap;
}

.centerItems {
  justify-content: center;
}

.flexEndStyle {
  flex: 1;
  justify-content: flex-end;
  align-items: flex-end;
  padding-bottom: 10px;
  text-align: center;
}

.verifyCodeInput {
  width: 360px;
}

.navListItemIcon {
  display: flex;
  flex-direction: row;
  padding: 17px;
  text-align: center;
  cursor: pointer 
}
.navListItemIconheader {
  display: flex;
  flex-direction: row;
  justify-content: center;
  cursor: pointer 
}
.navListItemfooter {
  display: flex;
  flex-direction: row;
  padding: 15px;
  width: 100%;
  cursor: pointer 
}
.navListItemfooter:hover {
  display: flex;
  flex-direction: row;
  padding: 15px;
  cursor: pointer 
}

.ActionIcons {
  padding-left: 0px !important;
  text-align: center !important;
  transition: 100ms ease-out;
}
.navListItem {
  margin: 0px;
  /* width: 100%; */
  height: 100%;
  /* border-bottom: none; */
  border-color: #3b9a83 !important;
  transition: border-bottom 0.1s ease-out;
  cursor: pointer 
}

.navListItem:hover {
  /* width: 100% !important; */
  border-bottom: 3px solid #3b9a83;
  /* border-radius: 10px; */
  cursor: pointer 
}

#navDrawer .MuiPaper-root {
  padding-top: 15px;
  background-color: #3b9a83 !important;
  border-right: unset !important;
  width: 65px;
  transition: width 200ms ease-out;
}

#navDrawer:hover .MuiPaper-root {
  width: 15rem;
  box-shadow: 5px 0px 20px 1px rgba(0, 0, 0, 0.3) !important;
  transition: width 200ms ease-out;
}

.smallMenuOpen .MuiPaper-root {
  padding-top: 15px;
  background-color: #3b9a83 !important;
  border-right: unset !important;
  width: '100%';
  transition: width 200ms ease-out;
}

.iconNameSmall {
  color: #ffffff !important;
  /* font-family: 'Montserrat-Regular'; */
  /* padding-left: 30px !important;
  padding-top: 5px !important; */
  font-size: 16px;
  font-weight: 700;
  text-align: center !important;
  width: 100%;
}

.iconName {
  color: #434343 !important;
  font-family: 'Montserrat Variable';
  /* padding-left: 30px !important;
  padding-top: 5px !important; */
  font-size: 16px;
  font-weight: 700;
  text-align: center !important;
  width: 100%;
  transition: width 200ms ease-out;
  margin-bottom: 0;
}

.menuLogo {
  transition: 200ms ease-in-out;
  width: 38px;
  margin-left: -5px;
  margin-top: -3px;
}
#navDrawer:hover .menuLogo {
  transform: rotate(-180deg);
  margin-top: -2px;
}
#navDrawer:hover .iconName {
  transition: width 200ms ease-out;
}

.neritoLogo {
  margin-top: -10px;
  margin-left: 25px !important;
  display: block !important;
  text-align: center !important;
  transition: width 200ms ease-out;
  /* width: 60%; */
  min-width: 100px;
}

.navList {
  width: 100% !important;
  height: 100%;
  display: flex !important;
  flex-direction: row !important;
  align-items: center !important;
  justify-content: space-between !important;

}

.globalOptions {
  display: flex !important;
  flex-direction: row !important;
}

.globalOptionsContainer {
  display: flex;
  flex-direction: row;
}

.ActionIcons {
  width: 100% !important;
  display: flex !important;
  flex-direction: row;
  align-items: center !important;
  justify-content: center;
}

.menu-icon {
  display: none;
  color: #3B9A83;
}

.navList .iconName {
  display: none !important;
  align-items: center;
}
.navList:hover .iconName {
  display: block !important;
  align-items: center;
}

#primary-search-account-menu .MuiPaper-root {
  background-color: white !important;
}

.styleCircularProgress {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  height: 80vh;
  width: 100vw;
}

secondHeaderShadow {
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
}

.SubContainer {
  text-align: center;
  width: 300px;
  align-self: center;
}

.SubContainer2 {
  text-align: center;
  width: 360px;
  align-self: center;
}
.imgContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px;
  margin-top: 30px;
  border-radius: 5px;
  border: 1px solid #e0e0e0;
  width: 100% !important;
  margin-bottom: 20px !important;
}

.title {
  margin-top: 35px !important;
  margin-bottom: 35px !important ;
  font-family: Montserrat Variable !important;
  font-style: normal !important;
  font-weight: bold !important;
  font-size: 24px !important;
  color: #434343;
}
.storeHeader {
  margin-top: 12px !important;
  margin-bottom: 0px !important;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}


.subtitle {
  font-family: Montserrat Variable !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  color: #434343;
}

.subText1 {
  font-family: Montserrat Variable !important;
  font-style: normal;
  font-weight: 600 !important;
  font-size: 14px !important;
  color: #434343;
}

.leftAlign {
  text-align: left !important;
}

.mainContainerRegister .subText1 {
  font-family: Montserrat Variable !important;
  margin-top: 4vh !important;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  color: #434343;
}

.subText1Pointer {
  font-family: Montserrat Variable !important;
  font-style: normal;
  font-weight: 700 !important;
  font-size: 14px !important;
  color: #3b9a83;
  padding-left: 5px;
  cursor: pointer;
}

.subText2 {
  font-family: Montserrat Variable !important;
  font-weight: 600;
  font-size: 16px;
  color: white !important;
}

.subText2Pointer {
  font-family: Montserrat Variable !important;
  font-weight: 600;
  font-size: 16px;
  color: #eddd64;
  padding-left: 5px;
}

.inputForm {
  padding: 0px 0px 0px 0 !important;
}

.inputFormCountryCode {
  padding: 28px 0px 0px 0 !important;
}

.messageTex {
  font-family: Montserrat Variable !important;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  color: #434343;
}

.profileTabs {
  box-shadow: unset !important;
  background-color: #fafafa !important;
}

.tab.anles {
  background-color: tomato !important;
}

.merchantTabs {
  box-shadow: unset !important;
  background-color: #fafafa !important;
}

.merchantPanel {
  margin-top: 20px !important;
}

.storesTabs {
  box-shadow: unset !important;
  background-color: #fafafa !important;
  padding-bottom: 20px;
}

.MuiTabs-flexContainer {
  display: flex;
}

.profileTabs .Mui-selected .MuiTab-wrapper {
  color: #434343 !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 12px !important;
  line-height: 19px !important;
  text-align: left !important;
}

.storesTabs .Mui-selected .MuiTab-wrapper {
  color: #434343 !important;
  font-style: normal !important;
  font-weight: 900 !important;
  font-size: 14px !important;
  line-height: 19px !important;
  text-align: center !important;
}

.merchantTabs .Mui-selected .MuiTab-wrapper {
  color: #434343 !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 14px !important;
  line-height: 19px !important;
  text-align: center !important;
}

.profileTabs .MuiTab-wrapper {
  color: #bdbdbd !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 12px !important;
  line-height: 19px !important;
  text-align: left !important;
}

.merchantTabs .MuiTab-wrapper {
  color: #555555 !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 12px !important;
  line-height: 19px !important;
  text-align: center !important;
}

.storesTabs .MuiTab-wrapper {
  color: #434343 !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 19px !important;
  text-align: center !important;
}

.profileTabs .MuiTabs-indicator {
  background-color: #eddd64;
  border-radius: 5px !important;
  z-index: 1;
  height: 4px;
  bottom: 20px !important;
}

.storesTabs .MuiTabs-indicator {
  background-color: #eddd64;
  border-radius: 5px;
  z-index: 1;
  height: 4px;
}

.merchantTabs .MuiTabs-indicator {
  background-color: #eddd64;
  max-width: 180px !important;
  border-radius: 5px;
}

.profileTabs .MuiTab-root {
  min-width: 285px;
  margin-bottom: 20px;
}
.Home-root-1 {
  display: unset;
}
.merchantTabs .MuiTab-root {
  min-width: 150px;
  padding: 0px 12px !important;
}


.linkReport {
  color:white;
  text-decoration: none;
}

.linkReport:hover {
  color:white;
  text-decoration: none;
}
/* Custom Styles */

.gralinfo_form {
  display: flex;
  justify-content: center;
  height: inherit;
  align-items: center;
}

input[type='file'].uploadDocument {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.outlinedSearch {
  padding-left: 20px;
  display: flex;
  align-items: center;
  width: 100%;
  height: 40px;
  font-size: 14px !important;
  background: #ffffff;
  border: 1px solid #e0e0e0 !important;
  box-sizing: border-box;
  border-radius: 5px;
}
.outlinedSearchText {
  width: 100%;
  font-size: 14px !important;
  background: transparent;
  padding-bottom: 2px !important;
}

.outlinedSearchSmallText {
  padding-left: 20px;
  display: flex;
  align-items: center;
  width: 100%;
  height: 40px;
  font-size: 14px !important;
  background: white !important;
  border: 1px solid #e0e0e0 !important;
  box-sizing: border-box;
  border-radius: 5px;
  margin-top: 10;
}
.outlinedSearchSmall {
  background: white !important;
}
.form-control {
  font-family: 'Montserrat Variable';
  font-style: unset;
}
.outlinedSearch .MuiInput-underline:before {
  border-bottom: unset !important;
}

.outlinedSearch .MuiInput-underline:after {
  border-bottom: unset !important;
}

.outlinedSearch .MuiOutlinedInput-input {
  padding: unset;
}

.outlinedSearch .MuiInputBase-input {
  padding: 9px 10px 7px 20px;
  font-size: 14px !important;
  font-weight: 400 !important;
}
.MuiInput-underline:before {
  border-bottom: unset !important;
}
.outlinedSearchSmall .MuiInput-underline:before {
  border-bottom: unset !important;
}

.outlinedSearchSmall .MuiInput-underline:after {
  border-bottom: unset !important;
}

.outlinedSearchSmall .MuiOutlinedInput-input {
  padding: unset;
}

.outlinedSearchSmall .MuiInputBase-input {
  padding: 9px 10px 7px 20px;
  font-size: 14px !important;
  font-weight: 400 !important;
}

.eyeIcon .MuiSvgIcon-root {
  padding: 2px;
}

.countryCodeSelect {
  color: #434343 !important;
  outline: none;
  background-color: fade(#fff, 80%);
  font-weight: bold !important;
  font-family: Montserrat Variable !important;
  font-style: normal;
  width: 40px !important;
  margin-right: 40px !important;
  padding-bottom: 2px !important;
  font-size: 14px !important;
}

.MuiCard-root {
  box-shadow: unset !important;
  border-radius: 5px !important;
  transition: 400ms ease-out !important;
}

.MuiCard-root:hover {
  box-shadow: 3px 20px 30px 3px rgba(0, 0, 0, 0.1) !important;
  border-radius: 5px !important;
}

.table th,
.table td {
  padding: unset !important;
  vertical-align: none !important;
  border: unset !important;
}
.table {
  background-color: white !important;
}

.table td {
  padding: 3px !important;
  vertical-align: none !important;
}

.rounded {
  border-radius: 0.25rem !important;
  padding: 0px 0px 0px 0px !important;
  max-width: 60vh;
  z-index: 4 !important;
}

.MuiNativeSelect-select.MuiNativeSelect-select {
  padding-right: 0px !important;
  display: flex !important;
  justify-content: center !important;
}

.newstoreform {
  z-index: 4 !important;
  position: relative !important;
}

.MuiCardContent-root {
  padding: 0px 0px 20px 0px !important;
  /* min-height: 280px !important; */
}

col-12,
.mt-3 {
  margin-top: 0px !important;
  background-color: white;
}

.table {
  border-bottom: 1px solid #e0e0e0 !important;
}

.quantityModal h2 {
  font-size: 18px !important;
}

.menuProfile .MuiMenu-paper {
  position: absolute !important;
  width: 251px !important;
  background: #e0e0e0 !important;
}

.menuProfile .MuiMenu-list {
  background: rgba(224, 224, 224, 0.1) !important;
  border: 1px solid #e0e0e0;
  font-family: Montserrat Variable;
  font-style: normal !important;
  font-weight: bold !important;
  font-size: 14px !important;
  line-height: 35px !important;
  color: #3b9a83 !important;
  padding-left: 15px;
}

.tooltip {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black;
  opacity: 1 !important;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  margin-left: -60px;
  opacity: 0;
  transition: opacity 0.3s;
  font-size: 10px;
}

.tooltip .tooltiptext::after {
  content: '';
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}

.borderLeft {
  border-left-width: 1px;
  border-left-style: solid;
}

.MuiCheckbox-root {
  color: #3b9a83 !important;
}

*:focus {
  outline: none !important;
}

.outlinedButton {
  outline: solid;
  box-shadow: 0 0 0 2pt;
}

.weekCheckbox {
  margin-left: 0px !important;
  margin-right: 0px !important;
}

.weekCheckboxReduce {
  margin-left: 0px !important;
  margin-right: 0px !important;
  zoom: 75%;
}

.weekCheckbox .MuiTypography-body1 {
  font-size: 10px !important;
}

.checkboxLabelMargin .MuiFormControlLabel-labelPlacementStart {
  margin-left: 0px !important;
}

.checkboxLabel .MuiTypography-root {
  font-family: Montserrat Variable !important;
  font-style: normal !important;
  font-weight: bold !important;
  font-size: 12px !important;
}

.dateInput .MuiFormControl-root,
.MuiFormControl-root.MuiTextField-root.defaultInputSearchTextFormLarge,
.MuiInput-underline.MuiInputBase-formControl.MuiInput-formControl {
  width: 100% !important;
}

.store-list {
  list-style-type: none;
}
.list-container {
  max-height: 50vh;
}

.btn-download {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 8rem;
  height: 90px;
  justify-content: center;
  margin: 0px 3px 5px;
}

.btn-edit {
  max-width: 3rem;
  max-height: 3rem;
}
.img-input {
  background-color: #f9f9f9;
  overflow: hidden;
  text-align: center;
}

.filterDate {
  justify-content: center;
  margin: auto;
  display: flex;
}
.MuiDrawer-paper.MuiDrawer-paperAnchorLeft.MuiDrawer-paperAnchorDockedLeft.MuiPaper-elevation0 {
  overflow-x: hidden;
}

.MuiContainer-root {
  padding: 0px !important;
}
.scrollable {
  overflow: scroll;
}
.MuiBackdrop-root {
  background-color: unset !important;
}
.MuiDrawer-paper {
  border: 1px solid #e0e0e0;
  border-radius: 5px 0px 0px 5px;
  box-shadow: unset !important;
  transition: width 200ms ease-out !important;
}
#scrollable-auto-tabpanel-2 .MuiBox-root {
  padding: 0px !important;
}
#scrollable-auto-tabpanel-1 .MuiBox-root {
  padding: 0px !important;
}
.documentationContainer .MuiCardContent-root {
  width: 100% !important;
  background-color: #f9f9f9;
  min-height: unset !important;
  border: 1px solid #e0e0e0;
  /* padding: 24px !important; */
}
.documentationContainer {
  width: 100% !important;
  height: unset !important;
  /* margin-left: 30px; */
}

.inputFormMerchant .MuiSelect-selectMenu {
  font-family: Montserrat Variable !important;
  font-size: 14px !important;
}

.inputFormMerchant .MuiFormHelperText-root {
  font-family: Montserrat Variable !important;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  display: flex;
  align-items: center;
  color: #da7b7b;
  margin-top: -5px;
}

.defaultSelectFormLarge .MuiInputBase-input {
  color: #434343 !important;
  font-weight: 600 !important;
  width: 100% !important;
  margin-bottom: 0 !important;
  padding-bottom: 0 !important;
}

/*********** RESPONSIVE CLASSES ************/

/* MOBILE at 43em (688px)*/
@media (max-width: 43em) {
  .action-bar {
    flex-direction: column;
    align-items: flex-start;
  }
  .outlinedSearch {
    width: 100%;
  }
  .btn-store {
    width: 50vw;
  }
  .padding_left_right_30 {
    padding-left: 30px !important;
    padding-right: 30px !important;
  }
  .MuiButton-text {
    font-size: 0.6rem !important;
  }

  .lbl-total {
    max-width: 6rem !important;
    margin: auto !important;
  }

  .lbl-total p:nth-child(2),
  .lbl-total > button > span {
    font-size: small !important;
  }
  .MuiInputBase-root {
    font-size: small !important;
  }
  .neritoLogo {
    margin-top: 10px;
  }
  .MuiBox-root {
    padding: 0px !important;
  }
  .filterDate {
    padding: 20px 0px;
  }
  .outlinedSearchSmall {
    width: 100%;
  }
  .inputContainer {
    width: 100% !important;
  }
}

/*TABLET at 62em (992px)*/
@media (min-width: 43em) and (max-width: 62em) {
  .MuiButton-text {
    font-size: 0.6rem !important;
  }
  .lbl-total {
    max-width: 8rem !important;
  }
  .lbl-total p:nth-child(2),
  .lbl-total > button > span {
    font-size: small !important;
  }
  .filterDate {
    padding: 20px 0px;
  }
}

@media screen and (max-width: 960px) {
  .navList {
    width: 100% !important;
    height: 100px;
    display: flex !important;
    flex-direction: column !important;
    align-items: center !important;
    transition: height 2s;
  }

  .navList.active {
    height: 550px;
    transition: height 2s;
  }

  .neritoLogo {
    margin-top: 10px;
    display: block !important;
    transition: width 200ms ease-out;
    width: 55%;
    justify-content: center;
    min-width: 100px;
  }

  .ActionIcons {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    transition: all 2s ease;
    top: -440px;
  }

  .ActionIcons.active {
    width: 100% ;
    top: 80px;
    transition: all 2s ease;
    height: 400px;
  }

  .menu-icon {
    display: block;
    position: absolute;
    top: 0;
    left: 60px;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
    color: #3B9A83;
  }

  .navListItemfooter {
    display: flex;
    flex-direction: row;
    width: 100%;
    left: 0;
    transition: 0.5s ease;
    cursor: pointer 
  }

  .globalOptionsContainer {
    width: 100% ;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    transition: all 2s ease;
    top: -220px;
  }

  .globalOptionsContainer.active {
    top: 480px;
    transition: all 2s ease;
  }

  .storeHeader {
    margin-top: 12px !important;
    margin-bottom: 0px !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .filterDate {
    justify-content: center;
    margin: auto;
    display: flex;
    flex-direction: column;
  }

  .profileOption {
    margin: 0px;
    border-color: #3b9a83 !important;
    display: block;
    position: absolute;
    right: 30px;
    top: 35px;
  }
}

.inputFileType {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0,0,0,0);
  border: 0;
}